import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router';
import { showToast } from '@/components/Notification';
import { useStoreSite } from '@/stores/store.site'
import { useStoreAccount } from '@/stores/store.account'
import { useStoreCompany } from '@/stores/store.company'
import Constant from "@/helpers/Constant";
import Cookies from 'js-cookie'
import { useGtm } from '@gtm-support/vue-gtm';
import Utils from "./helpers/Utils";


const routes = [
  {
    path: '/',
    name: 'landing',
    component: () => import('./views/Site.Landing.vue'),
    meta: { layout: "blank" },
  },
  {
    path: "/authenticate",
    name: "authenticate",
    component: () => import("./views/Site.Spinner.vue"),
    meta: { layout: "blank", requireAuth: false },
  },
  {
    path: '/auth-callback',
    name: 'auth-callback',
    component: () => import('./views/Auth.Callback.vue'),
    meta: { layout: 'blank', requireAuth: false },
  },
  {
    path: '/auth-preview',
    name: 'auth-preview',
    component: () => import('./views/Auth.Preview.vue'),
    meta: { layout: 'blank', requireAuth: false },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('./views/Corpsec.Dashboard.vue'),
    meta: { layout: "main-800px", requireAuth: true },
  },
  {
    path: '/pending-processing',
    name: 'pending-processing',
    component: () => import('./views/Corpsec.Processing.vue'),
    meta: { layout: "main-800px", requireAuth: true },
  },
  {
    path: '/start-incorporate',
    name: 'start-incorporate',
    component: () => import('./views/Corpsec.Incorp.Banner.vue'),
    meta: { layout: "main-800px", requireAuth: true },
  },
  {
    path: '/company',
    name: 'company',
    redirect: '/company/information',
    meta: { requireAuth: true, breadcrumb: { label: 'Company', to: '/company' } },
    children: [
      {
        path: 'information',
        name: 'company-information',
        component: () => import('./views/company/Corpsec.Company.vue'),
        props: { activeIndex: 0 },
        meta: { layout: "main-800px", requireAuth: true },
      },
      {
        path: 'member',
        name: 'company-member',
        component: () => import('./views/company/Corpsec.Company.vue'),
        props: { activeIndex: 1 },
        meta: { layout: "main-800px", requireAuth: true },
      },
      {
        path: 'share-information',
        name: 'share-information',
        component: () => import('./views/company/Corpsec.Company.vue'),
        props: { activeIndex: 2 },
        meta: { layout: "main-800px", requireAuth: true },
      },
    ],
  },
  {
    path: '/request',
    name: 'request',
    redirect: '/request/overview',
    meta: { requireAuth: true, breadcrumb: { label: 'Request', to: '/request' } },
    children: [
      {
        path: 'overview',
        name: 'overview',
        component: () => import('./views/request/Corpsec.Request.vue'),
        props: { activeIndex: 0 },
        meta: { layout: "main-800px", requireAuth: true },
      },
      {
        path: ':id/detail',
        name: 'request-detail',
        component: () => import('./views/request/Corpsec.RequestDetail.vue'),
        meta: { layout: "main-800px", breadcrumb: { label: 'Request Detail', to: '/request/:id/detail' } },
      },
      {
        path: ':id/payment',
        name: 'request-detail-payment',
        component: () => import('./views/request/Corpsec.RequestDetailPayment.vue'),
        meta: { layout: "main", requireAuth: true },
      },
      {
        path: 'new',
        name: 'request-new',
        component: () => import('./views/request/Corpsec.RequestType.vue'),
        meta: { layout: "main-800px", breadcrumb: { label: 'New Request', to: '/request/new' } },
      },
      {
        path: 'new/:slug',
        name: 'request-info',
        component: () => import('./views/request/Corpsec.RequestNewInfo.vue'),
        meta: { layout: "main-800px" },
      },
      {
        path: 'form',
        redirect: "/request/new"
      },
      {
        path: 'form/:slug',
        name: 'request-form',
        component: () => import('./views/request/Corpsec.RequestNewForm.vue'),
        meta: { layout: "main-800px" },
      },
    ],
  },  
  {
    path: '/document',
    name: 'document',
    component: () => import('./views/document/Corpsec.Document.vue'),
    meta: { layout: "main-800px", requireAuth: true },
  },
  {
    path: '/timeline',
    name: 'timeline',
    component: () => import('./views/timeline/Corpsec.Timeline.vue'),
    meta: { layout: "main-800px", requireAuth: true },
  },


  {
    path: '/onboarding',
    name: 'onboarding',
    redirect: '/onboarding/1',
    // component: () => import('./views/onboarding/Step.vue'),
    meta: { layout: "onboard", requireAuth: true },
    children: [
      {
        path: '/onboarding/1',
        name: 'step-1',
        component: () => import('./views/onboarding/Step01.vue'),
      },
      {
        path: '/onboarding/2',
        name: 'step-2',
        component: () => import('./views/onboarding/Step02.vue')
      },
      {
        path: '/onboarding/3',
        name: 'step-3',
        component: () => import('./views/onboarding/Step03.vue')
      },
      {
        path: '/onboarding/4',
        name: 'step-4',
        component: () => import('./views/onboarding/Step04.vue'),
      },
      {
        path: '/onboarding/5',
        name: 'step-5',
        component: () => import('./views/onboarding/Step05.vue')
      },
      {
        path: '/onboarding/6',
        name: 'step-6',
        component: () => import('./views/onboarding/Step06.vue')
      },
      {
        path: '/onboarding/7',
        name: 'step-7',
        component: () => import('./views/onboarding/Step07.vue'),
      },
      {
        path: '/onboarding/8',
        name: 'step-8',
        component: () => import('./views/onboarding/Step08.vue')
      },
      {
        path: '/onboarding/9',
        name: 'step-9',
        component: () => import('./views/onboarding/Step09.vue')
      },
      // {
      //   path:'/onboarding/email-verification',
      //   name: 'email-verification',
      //   component:() => import('./views/onboarding/EmailNotify.vue')
      // },
    ]
  },
  {
    path: '/onboarding/public',
    name: 'onboarding-public',
    redirect: '/onboarding/public/1',
    // component: () => import('./views/onboarding/Step.vue'),
    meta: { layout: "onboard", requireAuth: false },
    children: [
      {
        path: '/onboarding/public/1',
        name: 'step-1-public',
        component: () => import('./views/onboarding/public/Step01.vue'),
      },
      {
        path: '/onboarding/public/2',
        name: 'step-2-public',
        component: () => import('./views/onboarding/public/Step02.vue')
      },
      {
        path: '/onboarding/public/3',
        name: 'step-3-public',
        component: () => import('./views/onboarding/public/Step03.vue')
      },
      {
        path: '/onboarding/public/4',
        name: 'step-4-public',
        component: () => import('./views/onboarding/public/Step04.vue'),
      },
      {
        path: '/onboarding/public/5',
        name: 'step-5-public',
        component: () => import('./views/onboarding/public/Step05.vue')
      },
      {
        path: '/onboarding/public/6',
        name: 'step-6-public',
        component: () => import('./views/onboarding/public/Step06.vue')
      },
      {
        path: '/onboarding/public/7',
        name: 'step-7-public',
        component: () => import('./views/onboarding/public/Step07.vue'),
      },
      {
        path: '/onboarding/public/8',
        name: 'step-8-public',
        component: () => import('./views/onboarding/public/Step08.vue')
      },
      {
        path: '/onboarding/public/9',
        name: 'step-9-public',
        component: () => import('./views/onboarding/public/Step09.vue')
      },
      // {
      //   path:'/onboarding/email-verification',
      //   name: 'email-verification',
      //   component:() => import('./views/onboarding/EmailNotify.vue')
      // },
    ]
  },
  {
    path: '/onboarding/email-verification',
    name: 'email-verification',
    component: () => import('./views/onboarding/EmailNotify.vue'),
    meta: { layout: "blank", requireAuth: false },
  },
  {
    path: '/share-compliance',
    name: 'share-compliance',
    redirect: '/share-compliance/add-members',
    component: () => import('./views/Site.shareCompliance.vue'),
    meta: { layout: "main", requireAuth: true },
    children: [
      {
        path:'add-members',
        name:'add-members',
        component: () => import('./views/Site.shareCompliance.addMember.vue')
      },
      {
        path: 'allocate-company-shares',
        name: 'allocate-company-shares',
        component: () => import('./views/Site.shareCompliance.shares.vue')
      }
    ]
  },
  {
    path: '/incorporate',
    name: 'incorp',
    redirect: '/incorporate/setup',
    component: () => import('./views/Site.incorporate.vue'),
    meta: { layout: "main", requireAuth: true },
    children: [
      {
        path: 'setup',
        name: 'setup',
        component: () => import('./views/Site.onBoarding.info.vue'),
      },
      {
        path: 'address',
        name: 'address',
        component: () => import('./views/Site.onBoarding.address.vue')
      },
      {
        path: 'nominee-director',
        name: 'nominee-director',
        component: () => import('./views/Site.onBoarding.nomineeDirector.vue')
      }
    ]
    //step 2 compliance, step3 documents
  },
  {
    path: '/payment',
    name: 'payment',
    children: [
      {
        path: 'success',
        name: 'success',
        component: () => import('./views/Payment.Success.vue'),
      },
      {
        path: 'fail',
        name: 'fail',
        component: () => import('./views/Payment.Fail.vue'),
      }
    ]
  },

  {
    path: '/document-sign',
    name: 'document-sign',
    redirect: '/document-sign/review/invalid',
    children: [
      {
        path: "review",
        redirect: '/document-sign/review/invalid',
      },
      {
        path: "review/:token",
        name: 'document-sign-review',
        component: () => import('./views/document-sign/Sign.Verify.vue'),
        //component: () => import('./views/document-sign/Sign.Review.vue'),
        meta: { layout: "blank", requireAuth: false },
      },
    ],
  },


  {
    path: '/:catchAll(.*)*',
    name: 'bad-not-found',
    //redirect: '/',
    component: () => import('./views/Site.NotFound.vue'),
    meta: { layout: 'blank' },
  },
];

const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      const pos = savedPosition || to.meta?.scrollPos || { top: 0, left: 0 };
      setTimeout(() => {
        // //console.log("savedPosition to ---->", to);
        resolve(pos);
      }, 200);
    });
  },
});


router.afterEach(async (to, from) => {
  if (to.meta.layout) {
    const storeSite = useStoreSite();
    storeSite.setLayout(to.meta.layout);
  }
  const gtm = useGtm();
  if (to.path) {
    gtm.trackEvent({
      event: 'content-view',
      pageTitle: to.path === "/" ? "CorpSec - Landing" : `CorpSec - ${Utils.formatPath(to.path)}`,
      pageUrl: window.location.href
    })
  }
});



router.beforeEach(async (to, from, next) => {
  const storeSite = useStoreSite();
  const storeAccount = useStoreAccount();

  const init = storeSite.init;
  if (!init) {
    //console.log("init", init);
    storeSite.initialise();
    //storeSite.getApiVersion();

    const wasLoggedIn = storeSite.wasLoggedIn;
    if (wasLoggedIn) {
      const flag = await storeAccount.resumeLogin();
      //console.log("resume", flag);
      //const flag = false;
      if (flag?.status == 'success') {
        // showToast("Resumed login.", "success");
        if (to.name == "landing") {
          //goto dashboard when try to sign in, when already logged in
          return next({ path: "/dashboard" });
        }
      }
    }
  }

  const isLoggedIn = storeAccount.isLoggedIn;
  if (to.meta.requireAuth) {
    //console.log("router to", to);
    if (!isLoggedIn) {
      // showToast("Please login.", "danger");
      return next({ path: "/" });
      // return next({path: '/', query:{ redirect:to.fullPath }});
    }
  }

  //from.meta?.scrollPos && (from.meta.scrollPos.top = window.scrollY);

  next();
});



export default router;








/*
const loadCompanies = async () => {
  const storeCompany = useStoreCompany();
  let getCompany = await storeCompany.getAllCompany()
  if(getCompany.status == "success"){
    
    const data = getCompany.data.sort((a,b)=>new Date(a.createdAt) - new Date(b.createdAt))
    //console.log("main sorted beforeEach---->",data)
    const storeComp = await storeCompany.storeCompany(data)
  }else{
    
    console.error("error getCompany")
  }
}
*/