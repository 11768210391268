import { useGtm } from '@gtm-support/vue-gtm';
import Cookies from 'js-cookie';
import _ from 'lodash';
import Utils from "@/helpers/Utils"
import { useStoreAccount } from "@/stores/store.account";

export const getCompanyName = (company) => {
  // return (company?.publishStatus == 'published'
  //   || company?.publishStatus == 'editing')
  // ? (company?.companyName
  //   || company?.companySetupDetail?.companyName
  //   || '-')
  // : company?.companySetupDetail?.companyName[0];
  return company?.companyName
};

const generateRandomString = (length) => {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for(let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
}
const generateCodeChallenge = async (codeVerifier) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(codeVerifier);

  return window.crypto.subtle.digest('SHA-256', data).then(hash => {
    let base64 = btoa(String.fromCharCode(...new Uint8Array(hash)));
    return base64.replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
  });
}
// place share functions project here
export default {
  track: (event, target, targetProperty,value, action="click") => {
    /*
    const gtm = useGtm();
    gtm.trackEvent({
      event: event,
      category: target,
      action: action,
      label: targetProperty,
      value: value,
      noninteraction: false,
    });
    */
  },
  downloadFile:(url, label)=>{
  const storeAccount = useStoreAccount();
  const headers = { Authorization: storeAccount.user.accessToken, Token: storeAccount.user.idToken };
  fetch(url, { headers })
    .then((resp) => (resp.status === 200 ? resp.blob() : Promise.reject("Error downloadFile")))
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = label;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(() => {});
  },
  generateRandomString:generateRandomString,
  //PKCE
  generatePKCE:async (url)=>{
    
    const state = Math.random().toString(36).substring(2); // Generate a new state value
    const codeVerifier = generateRandomString(50); // Generate a new code verifier
    const codeChallenge = await generateCodeChallenge(codeVerifier); // Generate the code challenge

    //store cookie for code_challenge later on auth-callback
    Cookies.set(`mpkce`, codeVerifier, {expires: 1})

    // console.log(codeChallenge)
    const refineURL = url.replace(/\{code\}/, codeChallenge).replace(/\{chmt\}/, "S256")

    // console.log(refineURL)

    return refineURL
  },
  capitalizeFirstLetter: (str) => {
    if (!str)
      return 
    return str.charAt(0).toUpperCase() + str.slice(1);
  },
  getUsername: (data) => {
    if (_.isEmpty(data.firstName) && _.isEmpty(data.lastName)) {
        return '-';
    } else if (_.isEmpty(data.firstName)) {
        return `${data.lastName}`;
    } else if (_.isEmpty(data.lastName)) {
        return `${data.firstName}`;
    } else {
        return `${data.firstName} ${data.lastName}`;
    }
  },

  //key down only
  numberOnlyInput:(event)=>{

    // Get the pressed key code
    const keyCode = event.which || event.keyCode;
    
      // if("0123456789".split("").indexOf(event.key) == -1) event.preventDefault()
      // Allow numbers 0-9 and specific keyboard control keys (e.g., backspace, delete, arrow keys)
      if (!((keyCode >= 48 && keyCode <= 57) ||  // 0-9
        (keyCode >= 96 && keyCode <= 105) || // Numeric keypad 0-9
        keyCode === 8 || // Backspace
        keyCode === 9 || // Tab
        keyCode === 13 || // Enter
        keyCode === 27 || // Escape
        (keyCode >= 37 && keyCode <= 40) || // Arrow keys
        keyCode === 46)) { // Delete key
          
        event.preventDefault();
      
    }
  },
  convertFileToBlob: async (file) => {
    return new Promise((res,rej)=>{
      const reader = new FileReader();
      reader.onload = function(event) {
        // The result is a Blob object
        const blob = new Blob([event.target.result], { type: file.type });
    
        // Now you can use the 'blob' as needed, e.g., upload it to a server
        // console.log("Converted Blob:", blob);
        res(blob)
      };
    
      reader.readAsArrayBuffer(file);
    });
  }
};

export const requestStatusType = {

  DRAFT: "draft",

  PENDING_PAYMENT: "pending payment",

  PENDING_KYC: "pending kyc",

  PENDING_USER_ACTION: "pending user action",

  PROCESSING: "processing",

  COMPLETED: "completed",

  CLOSED: "closed",

  OTHERS: "others",

  VALIDATED: "validated",

  CANCELLED: "cancelled",

  QUOTATION: "quotation",

};

export function getRequestStatusColor(status) {

  const statusLowerCase = status.toLowerCase()

  if (statusLowerCase == 'validated' || statusLowerCase == 'completed') {
    return 'success'
  } else if (statusLowerCase == 'draft') {
    return 'muted'
  } else if (statusLowerCase == 'pending payment' || statusLowerCase == 'pending kyc' ||statusLowerCase == 'pending user action' ) {
    return 'info'
  } else if (statusLowerCase == 'processing') {
    return 'muted'
  } else if (statusLowerCase == 'closed' || statusLowerCase == 'cancelled') {
    return 'error'
  } else if (statusLowerCase == 'quotation') {
    return 'warning'
  }
  
}



export const filterMemberRole = (arr, roles) => { 
  return _.filter(arr, (o) => { return roles.find(v => (o.memberRole === v)); })
}


//loynote: _tidy functions to prepare response data return from backend, to drop when backend is stablise
export const _tidyCompanyShareCapital = (d) => {
  const obj = [];
  for (var i = 0; i < d?.shareCapital?.length; i++) {
    const temp = d.shareCapital[i];
    temp.shareType = temp.shareType;
    temp.id = temp.shareType+"-"+temp.currency;
    temp.currency = temp.currency;
    temp.paidUpCapital = temp.paidUpCapital;
    temp.amountOfCapital = temp.amountOfCapital;
    delete temp.numberOfShares;
    delete temp.totalShare;
    if (temp.amountOfCapital > 0) {
      obj.push(temp)
    }
  }
  return obj;
}
export const _tidyCompanyShareholders = (d) => {
  const obj = [];
  for (var i = 0; i < d?.shareholders?.length; i++) {
    const temp = d.shareholders[i];
    const shareDetail = [];
    for (var j in temp.shareDetail) {
      const s = temp.shareDetail[j];
      s.id = s.shareType + "-" + s.currency;
      delete s.ownership;
      s.numberOfShares = s.remainingShares === undefined? s.numberOfShares : s.remainingShares;
      if (s.numberOfShares > 0) {
        shareDetail.push(s)
      }
    }
    temp.shareDetail = shareDetail;
    obj.push(temp);
  }
 return obj;
}

export const documentDownload = (getURL, fileName)=>{
  const storeAccount = useStoreAccount();
  const headers = { Authorization: storeAccount.user.accessToken, Token: storeAccount.user.idToken };
  fetch(getURL, { headers })
    .then((resp) => (resp.status === 200 ? resp.blob() : Promise.reject("Error downloadFile")))
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(() => {});
}


export const safeParseJSON = (jsonString) => {
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    return [];
  }
};

export const loadImageAsync = (url) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = url;
  });
}

export const getShareholderName = (d) => {
  if (d.shareholderType == 'company' || d.type == 'company') {
    return getCompanyName(d);
  } else {
    return getIndvName(d);
  }
};


export const getIndvName = (d) => {
  let n = '';
  if (!Object.keys(d).length) {
    return n;
  } else if (d.fullName) {
    n = d?.fullName;
  } else if(d.name){
    n = d?.name;
  }
  else {
    n = d?.firstName + " " + d?.lastName;
  }

  return _.toUpper(n).trim();
};