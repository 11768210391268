export default {
  SITE_TITLE: "MEYZER360 | CORPSEC",
  SITE_DESC: "MEYZER360 setup your Company, Bank accounts, process Payments and ​manage your Governance, Accounting and Compliance online.",
  SITE_KEYWORD: "Company Incorporation, meyzer360, MEYZER360, Digital Compliance Provider, Digital Banking, Cross Border Payments, O2O Digital Transformation, Business AI Solutions",

  STORAGE_WAS_LOGGED_IN: "was_logged_in",
  COOKIE_HAD_SHOWN_WELCOME: "had_shown_welcome",
  COOKIE_REFRESH_TOKEN: "rf2",
  COOKIE_PREFERED_SELECTED_COMPANY_ID: "prefered_selected_company_id",

  COOKIE_LOCALE: "locale", //langauge

  ENABLED: "enabled",
  DISABLED: "disabled",

};



export const notificationType = {
  // OTP: "otp",
  // EMAIL_OTP: "email_otp",
  // EMAIL_VERIFICATION: "email_verification",
  // INVITATION_SIGNUP: "invitation_signup",
  // NEW_ACCOUNT_PASSWORD: "new_account_password",
  // NEW_USER: "new_user",
  // RESET_PASSWORD: "reset_password",  
  // ACCOUNT_PASSWORD_RESET: "account_password_reset",
  COMPANY_ACCOUNT_INVITATION: "company_account_invitation",
  EXT_PURCHASE_MODULE_INVITATON: "ext_purchase_module_invitation",  
};


export const errorType = {
  FORM_NOT_FOUND: "form_not_found",
}


